<template>
  <div id="cashCollection">
    <v-row>
      <v-col cols="12" class="title-col">
        <div class="title">
          <h1>{{ $t("Cash Collection") }}</h1>
        </div>
      </v-col>
    </v-row>
    <v-form v-model="valid" ref="form" :lazy-validation="lazy">
      <v-row>
        <v-col cols="12" md="6">
          <div class="box ">
            <h4 class="second-title">{{ $t("Select Payment") }}</h4>
            <ul class="payment-methods text-center">
              <li class="text-center disabled">
                <p class="icon" @click="selectPaymentMethod('Visa')">
                  <i class="fas fa-money-check"></i>
                </p>
                <p class="text">{{ $t("Visa") }}</p>
              </li>

              <li class="text-center" :class="submitted ? 'disabled' : ''">
                <p
                  class="icon selectable"
                  :class="paymentMethod == 'Cash' ? 'active' : ''"
                  @click="selectPaymentMethod('Cash')"
                >
                  <i class="far fa-money-bill-alt"></i>
                </p>
                <p class="text">{{ $t("Cash") }}</p>
              </li>

              <li class="text-center" :class="submitted ? 'disabled' : ''">
                <p
                  class="icon selectable"
                  :class="paymentMethod == 'Bank Transfer' ? 'active' : ''"
                  @click="selectPaymentMethod('Bank Transfer')"
                >
                  <i class="fas fa-dollar-sign"></i>
                </p>
                <p class="text">{{ $t("Bank Transfer") }}</p>
              </li>

              <li class="text-center" :class="submitted ? 'disabled' : ''">
                <p
                  class="icon selectable"
                  :class="paymentMethod == 'Check' ? 'active' : ''"
                  @click="selectPaymentMethod('Check')"
                >
                  <i class="far fa-credit-card"></i>
                </p>
                <p class="text">{{ $t("Check") }}</p>
              </li>
            </ul>
          </div>
          <div class="selectInput">
            <label for="select payment" class="main-color">{{
              $t("Cash Point")
            }}</label>
            <v-select
              solo
              :items="paymentObject.cash_points"
              v-model="paymentObject.cash_point_id"
              :disabled="submitted"
              item-text="name"
              item-value="id"
              :rules="[validationRules.required]"
            >
            </v-select>
          </div>
        </v-col>
        <v-col cols="12" md="6">
          <div class="box">
            <v-row>
              <v-col cols="12" md="6">
                <p class="key">{{ $t("Transaction Id") }}</p>
                <p class="value">{{ paymentObject.transaction_id }}</p>
              </v-col>
              <v-col cols="10" md="5">
                <p class="key">{{ $t("Transaction Date") }}</p>
                <v-text-field
                  id="transaction_date"
                  autocomplete="off"
                  v-model="paymentObject.transaction_date"
                  solo
                  dense
                  :disabled="submitted"
                  @keydown.prevent
                ></v-text-field>
              </v-col>
              <v-col cols="2" md="1">
                <v-icon
                  medium
                  style="float: right;opacity: 0.5;margin-top: 40px;"
                  class="mr-2 main-color dateIcon"
                  >event</v-icon
                >
              </v-col>
              <v-row v-if="paymentMethod == 'Cash'">
                <v-col cols="12" md="6" style="padding-top: 0;">
                  <p class="key">{{ $t("Voucher Number") }}</p>
                  <p class="value">{{ paymentObject.voucher_number }}</p>
                </v-col>
              </v-row>
              <v-row v-if="paymentMethod == 'Bank Transfer'">
                <v-col cols="12" md="6" style="padding-top: 0;">
                  <p class="key">{{ $t("Transfer Number") }}</p>
                  <v-text-field
                    autocomplete="off"
                    v-model="paymentObject.transfer_number"
                    solo
                    dense
                    :rules="[validationRules.required]"
                    :disabled="submitted"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" md="5" style="padding-top: 0;">
                  <p class="key">{{ $t("Transfer Date") }}</p>
                  <v-text-field
                    id="transfer_date"
                    autocomplete="off"
                    v-model="paymentObject.transfer_date"
                    solo
                    dense
                    :rules="[checkTransferDate(paymentObject.transfer_date)]"
                    :disabled="submitted"
                    @keydown.prevent
                  ></v-text-field>
                </v-col>
                <v-col cols="6" md="1">
                  <v-icon
                    medium
                    style="float: right;opacity: 0.5;margin-top: 28px;"
                    class="mr-2 main-color dateIcon"
                    >event</v-icon
                  >
                </v-col>
              </v-row>
              <v-row v-if="paymentMethod == 'Check'">
                <v-col cols="12" md="6" style="padding-top: 0;">
                  <p class="key">{{ $t("Check Number") }}</p>
                  <v-text-field
                    autocomplete="off"
                    v-model="paymentObject.check_number"
                    solo
                    dense
                    :rules="[validationRules.required]"
                    :disabled="submitted"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" md="5" style="padding-top: 0;">
                  <p class="key">{{ $t("Check Date") }}</p>
                  <v-text-field
                    id="check_date"
                    autocomplete="off"
                    v-model="paymentObject.check_date"
                    solo
                    dense
                    :rules="[validateCheckDate(paymentObject.check_date)]"
                    :disabled="submitted"
                    @keydown.prevent
                  ></v-text-field>
                </v-col>
                <v-col cols="6" md="1">
                  <v-icon
                    medium
                    style="float: right;opacity: 0.5;margin-top: 28px;"
                    class="mr-2 main-color dateIcon"
                    >event</v-icon
                  >
                </v-col>
              </v-row>
            </v-row>
          </div>
          <div class="textAreaInput">
            <label for="textarea">{{ $t("Notes") }}</label>
            <v-textarea
              rows="2"
              v-model="paymentObject.notes"
              row-height="5"
              :disabled="submitted"
              solo
            ></v-textarea>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <label>{{ $t("Attach Receipt Code") }}</label>
          <v-autocomplete
            :items="receipts"
            item-text="id"
            item-value="id"
            solo
            v-model="paymentObject.receipt_id"
            :disabled="submitted"
          ></v-autocomplete>
        </v-col>
      </v-row>
    </v-form>
    <v-row>
      <v-col cols="12" md="2">
        <h3 class="second-title">{{ $t("Payment Details") }}</h3>
      </v-col>
      <v-col cols="12" md="10" class="info-list">
        <v-row>
          <v-col cols="6" md="3"
            ><p class="value">
              <strong>{{ $t("Name") }}:</strong>
              <StudentHoverMenu
                :studentName="data.invoice.applicant_datails.applicant_name"
                :studentId="data.invoice.applicant_datails.student_id"
                :userId="data.invoice.applicant_datails.student_id"
                v-if="data.invoice.applicant_datails.user_id"
              ></StudentHoverMenu></p
          ></v-col>
          <v-col cols="6" md="3"
            ><p class="value">
              <strong>{{ $t("Partner Group") }}:</strong>
              {{ data.invoice.applicant_datails.partner_group }}
            </p></v-col
          >
          <v-col cols="6" md="3"
            ><p class="value">
              <strong>{{ $t("ID") }}:</strong>
              {{ data.invoice.applicant_datails.applicant_code }}
            </p></v-col
          >
          <v-col cols="6" md="3"
            ><p class="value">
              <strong>{{ $t("Invoice Number") }}:</strong>
              {{ data.invoice.invoice.invoice_number }}
            </p></v-col
          >
        </v-row>
      </v-col>
    </v-row>
    <hr class="basic" />

    <v-row>
      <v-col cols="12" md="12">
        <v-data-table
          :options.sync="options"
          :headers="headers"
          :items="items"
          class="elevation-1 level1 "
          loading-text="Loading... Please wait"
          hide-default-footer
        >
          <template v-slot:item.counter="{ item }">
            <span>{{ items.indexOf(item) + 1 }}</span>
          </template>

          <template v-slot:item.due_date="{ item }">
            <v-icon
              v-if="item.type != 'tax'"
              color="#ff8972"
              class="calendar-icon"
              >calendar_today</v-icon
            >
            <span class="due-date">{{ item.due_date }}</span>
          </template>
          <template v-slot:item.net_amount="{ item }">
            <p v-if="item.type != 'tax' && !item.taxable">
              {{ item.amount - item.discount }}
            </p>
            <p v-if="item.type != 'tax' && item.taxable">
              {{
                item.amount +
                  (item.amount * items[items.length - 1]["due_amount"]) / 100 -
                  item.discount
              }}
            </p>
          </template>
          <template v-slot:item.amount="{ item }">
            <p v-if="item.type != 'tax' && !item.taxable">
              {{ item.amount }}
            </p>
            <p v-if="item.type != 'tax' && item.taxable">
              {{
                item.amount +
                  (item.amount * items[items.length - 1]["due_amount"]) / 100
              }}
            </p>
            <p v-if="item.type == 'tax'">{{ item.amount }}</p>
          </template>

          <template v-slot:item.due_amount="{ item }">
            <span v-if="item.type == 'tax'">{{ item.due_amount }}%</span>
            <span v-if="item.type != 'tax'">{{ item.due_amount }}</span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <hr class="second" />

    <v-row>
      <v-col cols="6" md="2" sm="6">
        <p class="stats">
          <strong>{{ $t("Total Before Tax") }}: </strong>
          {{ data.invoice.invoice.total_before_tax }}
        </p>
      </v-col>
      <v-col cols="6" md="2" sm="6">
        <p class="stats">
          <strong>{{ $t("Tax Amount") }}: </strong>
          {{ data.invoice.invoice.tax_amount }}
        </p>
      </v-col>
      <v-col cols="6" md="2" sm="6">
        <p class="stats">
          <strong>{{ $t("Total") }}: </strong> {{ data.invoice.invoice.total }}
        </p>
      </v-col>
      <v-col cols="6" md="3" sm="6">
        <p class="stats">
          <strong>{{ $t("Total After Discount") }}: </strong>
          {{ data.invoice.invoice.total_after_discount }}
        </p>
      </v-col>
      <v-col cols="6" md="3" sm="6">
        <p class="stats">
          <strong>{{ $t("Total Amount Paid") }}: </strong> {{ totalAmountPaid }}
        </p>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="12" class="text-center">
        <v-btn
          @click="updatePayment"
          color="#7297FF"
          :disabled="submitted || loading"
          :loading="loading"
          >{{ $t("Update Payment") }}</v-btn
        >
      </v-col>
    </v-row>
    <v-snackbar
      v-model="snack"
      :timeout="snackTime"
      :color="snackColor"
      :right="true"
      :top="true"
    >
      <!-- {{ snackText }} -->

      <span class="white--text">
        {{ snackText }}
      </span>

      <v-btn text @click="snack = false">{{ $t("Close") }}</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import { validationMixin } from "../../mixins/validationMixin";
import StudentHoverMenu from "@/components/Students/StudentHoverMenu";
export default {
  name: "EditPayment",
  mixins: [validationMixin],
  components: { StudentHoverMenu },
  data() {
    return {
      loading: false,
      submitted: false,
      headers: [
        {
          text: this.$i18n.t("Number"),
          align: "start",
          sortable: false,
          value: "counter",
        },
        { text: this.$i18n.t("Fee Type"), value: "item", sortable: false },
        {
          text: this.$i18n.t("Due Amount"),
          value: "due_amount",
          sortable: false,
        },
        { text: this.$i18n.t("Due Date"), value: "due_date", sortable: false },
        {
          text: this.$i18n.t("Net After Tax"),
          value: "amount",
          sortable: false,
        },
        { text: this.$i18n.t("Discount"), value: "discount", sortable: false },
        {
          text: this.$i18n.t("Amount Paid"),
          value: "amount_paid",
          sortable: false,
        },
        {
          text: this.$i18n.t("Net After Discount"),
          value: "net_amount",
          sortable: false,
        },
        { text: this.$i18n.t("Notes"), value: "notes", sortable: false },
        {
          text: this.$i18n.t("Remaining Amount"),
          value: "remaining_amount",
          sortable: false,
        },
      ],
      options: {
        itemsPerPage: 100,
      },
      payment_id: "",
      data: [],
      items: [],
      paymentObject: {},
      snack: false,
      snackColor: "",
      snackText: "",
      snackTime: 7000,
      paymentMethod: "Cash",
      valid: false,
      lazy: false,
      receipts: [],
    };
  },
  computed: {
    totalAfterDiscount: {
      get() {
        let totalDisount = this.total;
        this.items.forEach((item) => {
          if (item.discount != "" && item.discount != undefined) {
            totalDisount -= item.discount;
          }
        });
        return totalDisount;
      },
      // setter
      set: function(newValue) {
        this.totalAfterDiscount = newValue;
      },
    },
    totalAmountPaid: {
      get() {
        let totalPaid = 0;
        this.items.forEach((item) => {
          // console.log(item);
          if (item.amount_paid != "" && item.amount_paid != undefined) {
            totalPaid += Number(item.amount_paid);
          }
        });
        return totalPaid;
      },
      // setter
      set: function(newValue) {
        this.totalAmountPaid = newValue;
      },
    },
  },
  methods: {
    selectPaymentMethod(method) {
      if (!this.submitted) {
        this.$refs.form.resetValidation();
        this.paymentMethod = method;
        this.paymentObject.cash_point_id = "";
        var _this = this;
        if (this.paymentMethod == "Bank Transfer") {
          // this.paymentObject.voucher_number = null;
          this.paymentObject.check_number = null;
          this.paymentObject.check_date = null;
          $(document).ready(function() {
            $("#transfer_date").calendarsPicker({
              dateFormat: "dd/mm/yyyy",
              onSelect: function(date) {
                if (date[0]) {
                  var dateH =
                    date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
                  _this.paymentObject.transfer_date = dateH;
                } else {
                  _this.paymentObject.transfer_date = "";
                }
              },
            });
          });
          axios
            .get(this.getApiUrl + "/payments/getCashPoints/Transfer", {
              headers: {
                Authorization: "Bearer " + localStorage.token,
              },
            })
            .then((response) => {
              this.paymentObject.cash_points = response.data.data;
              this.$forceUpdate();
            });
        } else if (this.paymentMethod == "Check") {
          // this.paymentObject.voucher_number = null;
          this.paymentObject.transfer_number = null;
          this.paymentObject.transfer_date = null;
          $(document).ready(function() {
            $("#check_date").calendarsPicker({
              dateFormat: "dd/mm/yyyy",
              onSelect: function(date) {
                if (date[0]) {
                  var dateH =
                    date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
                  _this.paymentObject.check_date = dateH;
                } else {
                  _this.paymentObject.check_date = "";
                }
              },
            });
          });
          axios
            .get(
              this.getApiUrl + "/payments/getCashPoints/Check",

              {
                headers: {
                  Authorization: "Bearer " + localStorage.token,
                },
              }
            )
            .then((response) => {
              this.paymentObject.cash_points = response.data.data;
              this.$forceUpdate();
            });
        } else if (this.paymentMethod == "Cash") {
          this.paymentObject.check_number = null;
          this.paymentObject.check_date = null;
          this.paymentObject.transfer_number = null;
          this.paymentObject.transfer_date = null;
          axios
            .get(
              this.getApiUrl + "/payments/getCashPoints/Cash",

              {
                headers: {
                  Authorization: "Bearer " + localStorage.token,
                },
              }
            )
            .then((response) => {
              this.paymentObject.cash_points = response.data.data;
              this.$forceUpdate();
            });
        }
      }
    },
    getCashPoints(type) {
      if (type == "Bank Transfer") {
        type = "Transfer";
      }
      axios
        .get(
          this.getApiUrl + "/payments/getCashPoints/" + type,

          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          this.paymentObject.cash_points = response.data.data;
          this.$forceUpdate();
        });
    },
    checkTransferDate(value) {
      if (this.paymentMethod == "Bank Transfer") {
        return value ? true : "Transfer number is required";
      } else {
        return true;
      }
    },
    validateCheckDate(value) {
      if (this.paymentMethod == "Check") {
        return value != "" ? true : "Check Date is required";
      } else {
        return true;
      }
    },
    getReceipts() {
      axios
        .get(this.getApiUrl + "/payments/all_receipts", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          if (response.data.status.error == false) {
            this.receipts = response.data.data;
          }
        });
    },
    updatePayment() {
      if (!this.valid) {
        this.$refs.form.validate();
      } else {
        this.loading = true;
        axios
          .post(
            this.getApiUrl + "/payments/editTransaction/" + this.payment_id,
            {
              payment: this.paymentObject,
              type: this.paymentMethod,
              totalPaid: this.totalAmountPaid,
            },
            {
              headers: {
                Authorization: "Bearer " + localStorage.token,
              },
            }
          )
          .then((response) => {
            this.loading = false;
            if (response.data.status.error == false) {
              this.submitted = true;
              this.snack = true;
              this.snackColor = "success";
              this.snackText = "payment has been processed successfully";
            } else {
              this.snack = true;
              this.snackColor = "error";
              this.snackText = response.data.status.message;
            }
          });
      }
    },
  },
  mounted() {
    this.getReceipts();

    this.payment_id = this.$router.currentRoute.params.id;

    axios
      .get(this.getApiUrl + "/payments/show/" + this.payment_id, {
        headers: {
          Authorization: "Bearer " + localStorage.token,
        },
      })
      .then((response) => {
        this.data = response.data.data;
        this.items = this.data.invoice.items;
        this.paymentObject = this.data.transaction;
        this.paymentObject.notes = this.data.transaction.notes;
        this.paymentMethod = this.data.transaction.type;
        this.paymentObject.cash_points = [];
        this.getCashPoints(this.paymentMethod);
        // console.log(response.data);
      });
    setTimeout(() => {
      var _this = this;
      $(document).ready(function() {
        $("#transaction_date").calendarsPicker({
          dateFormat: "dd/mm/yyyy",
          onSelect: function(date) {
            if (date[0]) {
              var dateH =
                date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
              _this.paymentObject.transaction_date = dateH;
            } else {
              _this.paymentObject.transaction_date = "";
            }
          },
        });
        $("#transfer_date").calendarsPicker({
          dateFormat: "dd/mm/yyyy",
          onSelect: function(date) {
            if (date[0]) {
              var dateH =
                date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
              _this.paymentObject.transfer_date = dateH;
            } else {
              _this.paymentObject.transfer_date = "";
            }
          },
        });
        $("#check_date").calendarsPicker({
          dateFormat: "dd/mm/yyyy",
          onSelect: function(date) {
            if (date[0]) {
              var dateH =
                date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
              _this.paymentObject.check_date = dateH;
            } else {
              _this.paymentObject.check_date = "";
            }
          },
        });
      });
    }, "1300");
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/_variables.scss";
.info-list {
  .value {
    font-size: 13px;
  }
}
.paymentBtn {
  padding-top: 6px !important;
}
hr.basic {
  margin: 0;
}
hr.second {
  border: 2px solid $main-color;
  margin: 0 auto;
  background-color: $main-color;
}
.v-data-table th {
  height: 25px;
}

.box {
  box-shadow: $box-shadow;
  width: 90%;
  padding: 10px 10px;
  margin-bottom: 1rem;
  height: 222px;
  .key {
    margin-bottom: 8px;
  }
  .value {
    color: $main-gray;
    margin-bottom: 8px;
  }
}

.payment-methods {
  padding-left: 0;
  li {
    display: inline-block;
    width: 25%;

    .icon {
      font-size: 2rem;
      color: $main-color;
      margin-bottom: 8px;
      padding: 5px 20px 5px;
      margin: 0 5px;
      border-radius: 5px;
    }
    .text {
      color: $main-color;
    }
  }
}

.active {
  background-color: $main-backgroundColor;
}

.selectable {
  cursor: pointer;
}

.disabled {
  cursor: not-allowed;

  .icon {
    color: $main-gray !important;
  }

  .text {
    color: $main-gray !important;
  }
}

.selectInput {
  width: 90%;
  // padding-left: 12px;
  .v-input {
    max-width: 70%;
  }
}

.textAreaInput {
  // padding-left: 12px;
  width: 90%;
  label {
    color: $main-color;
  }
}

.calendar-icon {
  padding-right: 5px;
}
.due-date {
  color: #ff8972;
}

.second-title {
  color: $main-color;
}

@media (max-width: 600px) {
  .second-title {
    margin-bottom: 0;
  }
  .box {
    height: auto;
    width: 100%;
    .payment-methods {
      li {
        .icon {
          padding: 5px 10px 5px;
        }
      }
    }
  }

  .info-list .value {
    margin-bottom: 0;
  }

  .selectInput {
    width: 100%;
    .v-input {
      max-width: 100%;
    }
  }

  .textAreaInput {
    width: 100%;
  }
}
</style>
